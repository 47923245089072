export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 260;
export const CATEGORY_IMAGE_HEIGHT = 220;

export const BANNER_IMAGE_WIDTH = 1366;
export const BANNER_IMAGE_HEIGHT = 544;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 278;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 199;

export const MOBILE_BANNER_IMAGE_WIDTH = 350;
export const MOBILE_BANNER_IMAGE_HEIGHT = 469;

export const TESTIMONIALS_IMAGE_WIDTH = 25;
export const TESTIMONIALS_IMAGE_HEIGHT = 25;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 25;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 25;

export const DEFAULT_LAYOUT_SELECTION = 16;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH7';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB3';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS1';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS11';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial12';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'font-extrabold ';
export const SLIDER_TOP_PADDING = 'pt-0';
export const LAYOUT_CONFIG = {
  productList: {
    ShowTaxTag: false,
    showDiscountLabel: false,
  },
};
export const COMMON_TOAST_MESSAGE = {};
