import SEO from '@common/components/SEO';
import TrustBanner from '@common/components/TrustBanner';
import {
  addProductToCart,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import { CategoriesSliceType } from '@common/store/categories/slice';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { HomeSliceType } from '@common/store/home/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { fetchProductsRequest, ProductsSliceType } from '@common/store/products/slice';
import { fetchSearchRequest, SearchSliceType } from '@common/store/search/slice';
import { SliderSliceType } from '@common/store/slider/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToast, removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import { UserProfileSliceType } from '@common/store/userProfile/slice';
import { UserSectionsSliceType } from '@common/store/userSections/slice';
// import Branding from '@common/components/Branding';
import Footer from '@components/Footer';
import Toasts from '@components/Toasts';
import Navbar from '@layouts/Navbar';
import Slider from '@layouts/Slider';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';
export interface PageLayoutProps {
  children: React.ReactNode;
  topChildren?: React.ReactNode;
  cart: CartSliceType;
  products: Record<number, ProductsSliceType>;
  toasts: ToastWithKeyType[];
  storeInfo: StoreInfoSliceType;
  home: HomeSliceType;
  slider: SliderSliceType;
  search: Record<string, SearchSliceType>;
  addProductToCart: typeof addProductToCart;
  removeProductFromCart: typeof removeProductFromCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  displayBanner?: boolean;
  hideToast?: boolean;
  fetchSearchRequest: typeof fetchSearchRequest;
  customPages: CustomPagesSliceType;
  userProfile: UserProfileSliceType;
  userSections: UserSectionsSliceType;
  filterBar: FilterBarSliceType;
  policiesTos: PoliciesSliceTosType;
  categories: CategoriesSliceType;
  fetchProductsRequest: typeof fetchProductsRequest;
  features: FeaturesSliceType;
}

const PageLayout: React.FunctionComponent<PageLayoutProps> = ({
  customPages,
  // cart,
  toasts,
  children,
  // addProductToCart,
  // removeProductFromCart,
  // subtractProductFromCart,
  addToast,
  removeToast,
  storeInfo,
  slider,
  home,
  displayBanner = false,
  // fetchSearchRequest,
  // search,
  // userProfile,
  userSections,
  hideToast,
  filterBar,
  policiesTos,
  // categories,
  // fetchProductsRequest,
  // features,
}) => {
  const [ViewCart, setViewCart] = useState(false);
  const pageHeaderLayout = userSections.userSections?.find((section) => section.sectionType === 'Page Header');
  return (
    <div className="bg-page pageLayout">
      {pageHeaderLayout ? (
        <Navbar
          id={pageHeaderLayout.id}
          layoutName={pageHeaderLayout.layoutName}
          ViewCart={ViewCart}
          setViewCart={() => {
            setViewCart(false);
          }}
        />
      ) : null}
      <SEO />
      {!hideToast && (
        <Toasts
          toasts={toasts}
          addToast={addToast}
          removeToast={removeToast}
          viewCart={() => {
            setViewCart(true);
          }}
        />
      )}

      {displayBanner && slider.isFetched && home.isFetched ? (
        <div>
          {home?.sections?.find((o) => o.type === 'banner').bannerEnabled ||
          home?.sections?.find((o) => o.type === 'banner').sliderEnabled ? (
            <Slider slider={slider} layout={home?.sections?.find((o) => o.type === 'banner')?.layout} />
          ) : null}
        </div>
      ) : null}
      {children}
      <TrustBanner storeInfo={storeInfo.storeInfo} />
      <Footer
        storeInfo={storeInfo.storeInfo}
        customPages={customPages}
        filterBar={filterBar}
        policies={policiesTos}
        pageHeaderID={pageHeaderLayout?.id}
      />
      {/* <Branding className="mt-40" /> */}
    </div>
  );
};

export default connect(
  ({
    cart,
    products,
    slider,
    toasts,
    storeInfo,
    home,
    search,
    customPages,
    userProfile,
    userSections,
    filterBar,
    policiesTos,
    categories,
    features,
  }: RootState) => ({
    search,
    home,
    cart,
    slider,
    products,
    toasts,
    storeInfo,
    customPages,
    userProfile,
    userSections,
    filterBar,
    policiesTos,
    categories,
    features,
  }),
  {
    addProductToCart,
    removeProductFromCart,
    subtractProductFromCart,
    addToast,
    removeToast,
    fetchSearchRequest,
    fetchProductsRequest,
  },
)(PageLayout);
