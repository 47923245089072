import React from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={`w-112 md:w-134 min-h-38 md:min-h-48 py-8 px-12 text-center text-14 leading-22 md:text-16 md:leading-25 font-semibold text-white bg-theme border-2 border-theme focus:outline-none pointer-events-auto rounded justify-center items-center disabled:cursor-not-allowed disabled:border-disabled disabled:text-disabled hover:bg-themeHover focus:bg-themeFocus flex items-center justify-center ${customClass}`}
          target="_blank"
          rel="noreferrer"
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          {text}
        </a>
      ) : null}
    </>
  );
};

export default Cta;
