// import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import blackClose from '@images/Close.svg';
import Close from '@images/whiteClose.svg';
import classnames from 'classnames';
import React from 'react';

import { WISHLIST_TOAST } from '../../../../common/store/wishList/slice';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
  viewCart?: () => void;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView, viewCart }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);

  return (
    <div className={classnames({ 'flex justify-center items-center': !quickView })}>
      <div
        className={classnames('', {
          'md:absolute md:right-0 md:w-full': quickView,
          'fixed top-80 w-fit z-999 container px-0': !quickView,
        })}
      >
        {toasts.map((toast) => (
          <div key={toast.key}>
            {quickView ? (
              <div
                className={
                  toast.variant === 'bg-toast-error'
                    ? 'bg-errorToast bg-opacity-10 text-errorToast md:mb-24 rounded shadow-md'
                    : 'bg-successToast bg-opacity-10 text-successToast md:mb-24 rounded shadow-md'
                }
              >
                <div
                  className={classnames(
                    'container mx-auto w-full flex font-medium items-center py-12 text-14 md:text-16 justify-between',
                    { 'px-10 py-12 border-0': quickView },
                  )}
                >
                  <div dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                  <img
                    src={blackClose}
                    alt="Close"
                    className=" mx-16 cursor-pointer h-10 md:h-14"
                    onClick={() => removeToast(toast.key)}
                  />
                </div>
              </div>
            ) : (
              <div className={'w-full z-999 p-20'}>
                <div
                  className={classnames(
                    'mx-auto bg-black text-white w-full max-w-580 flex items-center p-12 text-14 md:text-16 justify-between',
                  )}
                  font-customisation="para-text"
                >
                  <div className="max-w-400" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                  <div className="flex items-center cursor-pointer w-auto">
                    {toast?.options?.find((option) => option?.name == WISHLIST_TOAST) ? null : (
                      <div
                        className="underline font-medium mx-4 leading-22 w-80"
                        onClick={() => {
                          viewCart();
                          removeToast(toast.key);
                        }}
                      >
                        View Cart
                      </div>
                    )}
                    <div>
                      <img
                        src={Close}
                        alt="Close"
                        className=" mx-16 cursor-pointer h-14"
                        onClick={() => removeToast(toast.key)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Toasts;
